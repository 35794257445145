.container-fluid {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

.nav-tabs .nav-item .nav-link {
    color: slategrey;
}

.nav-tabs .nav-item .nav-link.active {
    color: white;
    background-color: slategrey;
    font-weight: normal;
}

.nav-tabs .nav-item .nav-link.disabled {
    color: lightsteelblue;
    font-weight: lighter;
}

.App {
    min-height: 800px;
}

.Container {
    margin: 0;
}

.Header {
    background: #A40600;
    height: 120px;
}

.ContentHeader {
    margin: 1em 3em 0em 3em;
    font-size: 1.25em;
}

.NameHotel {
    font-size: 1em;
}

.Anschrift {
    font-size: 0.75em;
}

.ProgressBar {
    margin-top: 0.8em;
}

.LanguageSwitcher {
    margin-top: 0.5em;
    height: 2.25em;
}

.Logo {
    width: 165px;
    height: 61px;
}

.Error-Logo-Container {
    padding-top: 2em;
    text-align: center;
}

.LogoContainer {
    padding: 2em 0 0 2em;
    position: relative;
    left: 3em;
    top: 0;
    background: white;
    width: 224px;
    height: 124px;
}

.Content {
    margin: 2em 3em 3em 3em;
    background: white;
    min-height: 400px;
}

h4 {
    color: black;
    padding-top: 0.6em;
    padding-bottom: 0.3em;
}

h5 {
    color: slategrey;
    padding-top: 0.6em;
    padding-bottom: 0.3em;
}

.InputPadding {
    padding-top: 0.6em;
    padding-bottom: 0.3em;
}

.Footer1 {
    background: #A40600;
    padding: 3em;
    vertical-align: center;
    text-align: center;
    color: white;
    min-height: 6em;
}

.Footer1 a {
    margin: 0.5em;
    font-weight: bold;
    color: white;
}

.Footer2 {
    background: #870A03;
    padding: 3em;
    vertical-align: center;
    text-align: center;
    color: white;
    min-height: 6em;
}

.Footer2 a {
    margin: 0.5em;
    font-weight: bold;
    color: white;
}

.Button {
    margin-top: 1em;
}

li.Conclusion {
    margin-top: .75em;
}

.Startpage-Logo-Container {
    padding: 0.25em 0 0.75em 0;
    text-align: center;
}

h2.Startpage {
    text-align: center;
}

h5.Startpage {
    color: black;
    font-size: 1em;
}

p.Startpage {
    color: slategrey;
}

a.Startpage {
    color: #A40600;
    text-decoration: none;
}

div.Error {
    padding: 2em;
    color: #A40600;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

div.PositiveError {
    padding: 2em;
    color: black;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.CChecked {
    border-radius: 0.5em;
    padding: 0.2em 0.5em 0.25em 0.5em;
    color: green;
}

.CUnchecked {
    border-radius: 0.5em;
    padding: 0.2em 0.5em 0.25em 0.5em;
    color: darkorange;
}

.CValue {
    padding: 0.2em 0.5em 0.25em 0.5em;
    color: black;
}

.CText {
    max-width: 90%;
    padding-top: 0.5em;
    color: black;
}

.CLabel {
    color: slategrey;
}
